import React from "react";
import Layout from "../components/UI/layout";
import SEO from "../components/UI/seo";
import Links from "../components/Link/link";

const IndexPage = () => (
    <Layout>
      <SEO title="Codeicus | Link" />
      <Links />
    </Layout>
  )
  
  export default IndexPage