import React from 'react';
import * as styles from './link.module.scss';
import axios from 'axios';



class Linked extends React.Component {

    constructor(props) {
		super(props)
		this.state = {
			intagram: [],
		}

	}

    getInfoJson = async () => {
        try {
            const {data} = await axios.get('https://codeicus.com/instagram/link.json');
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    async componentDidMount() {
        const url = await this.getInfoJson();
        console.log(url);
        this.setState({intagram: url.links});
    }

	render(){
        console.log(this.state);
        const linkPrint =  this.state.intagram.map((link) => 
            <div className={styles.cont}>
                <a href={link.url} >
                        <img src={link.img} alt= {link.name} />
                </a>
            </div>
            );
		return (
			<section className={styles.linkContent}>
                <div className={styles.contListLinks}>
                    {linkPrint}
                </div>
			</section>
		);
	}
}
export default Linked;